<script setup>

const props = defineProps({
    transactionId: {
        type: Number,
        required: true
    }
});

const transactionId = computed(() => props.transactionId);

const emit = defineEmits(["refresh"]);

const countdownTime = 20; // Countdown time in seconds
const timer = ref(countdownTime);
const isDisabled = ref(false);
const buttonText = ref(`Send again`);
const otpCode = ref('');
const verifying = ref(false);

const startCountdown = () => {
    isDisabled.value = true;
    buttonText.value = `Send again (Wait ${timer.value} seconds)`;

    const interval = setInterval(() => {
        timer.value--;

        if (timer.value >= 0) {
            buttonText.value = `Send again (Wait ${timer.value} seconds)`;
        }

        if (timer.value < 0) {
            clearInterval(interval);
            buttonText.value = 'Send again';
            isDisabled.value = false;
            timer.value = countdownTime;
        }
    }, 1000);
};

function sendAgain() {

    if (isDisabled.value) return;

    apiService.sendTransactionCodeAgain({
        withdrawalId: transactionId.value
    }).then(() => {
        successMessage('Verification code sent successfully');
        startCountdown();
    }).catch(e => {
        errorMessage(e);
    }).finally(() => {
        timer.value = countdownTime;
    })

}

function verifyTransaction() {

    if(!otpCode.value) {
        return;
    }

    verifying.value = true;

    let payload = {
        otp: otpCode.value,
    };

    apiService.verifyTransaction(transactionId.value, payload).then(() => {
        successMessage('Transaction verified successfully');
        emit('refresh');
    }).catch(e => {
        errorMessage(e);
    }).finally(() => {
        verifying.value = false;
    });

}

</script>

<template>
    <div class="mx-auto py-14" style="max-width: 600px;">
        <div class="text-center">
            <div class="text-black/50 text-lg">Authorize Transaction</div>
            <div class="mt-6 text-lg">
                <div class="my-6">We've sent you an email which contains a one-time verification code to authorize this transaction.</div>
            </div>
        </div>

        <form @submit.prevent="verifyTransaction" class="mt-10">
            <div class="mb-6">
                <div class="text-black/50 mb-4">One-time verification code</div>
                <input v-model="otpCode" type="text" placeholder="One time code" class="form-input">
            </div>

            <div class="text-center mt-10 flex gap-4 justify-center">
                <button type="button" class="btn bg-gray-300 hover:bg-black hover:text-white" :disabled="isDisabled" @click="sendAgain">
                    {{ buttonText }}
                </button>
                <button class="cursor-pointer btn bg-purple-light hover:bg-black hover:text-white" :disabled="!otpCode || verifying">
                    <LoadingIcon v-if="verifying" class="icon"/>
                    Verify
                </button>
            </div>

        </form>

    </div>
</template>